@import 'variables.scss';

.Feral {
  align-items: flex-end;
 
  

  .body {
    flex-direction: row-reverse;
    img {
        margin-top: 10px;
        margin-right: -30px;
        margin-left: 10px;
    }
  }

  &.open {
    right: 0vw;
  }

  &.closed {
    right: -200vw;
  }
}
