@import 'variables.scss';

.Home {
    position: absolute;
    inset: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    transition: width 400ms;
    margin: 0 auto;
    width: 100%;

    h2 {
      color: $accent-2;
      text-align: center;
      margin: 0;
      width: 100%;
      opacity: 1;
      transition: opacity 100ms;

      br {
        display: none
      }

      @media (max-width: $xs-break) {
        font-size: 18px;
      }
    }

    &.closed {
      width: 0vw;

      h2 {
        opacity: 0;
      }
    }
}

.banner {
  width: 100%;
  height: min-content;

  &-drawing {
    position: absolute;
    width: 100%;
    z-index: 5;
  }
  
  &-motion {
    width: 100%;
    inset: 0;
    z-index: 0;
  }
}

.lines { 
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 29.5vh;
    z-index: -20;

    &.open {
      opacity: 0;
      width: 0%;
    }
    
    &.closed {
      opacity: 0.3;
    }
}

