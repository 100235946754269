@import 'variables.scss';

.Header { 
  z-index: 30;
  background-image: $black-tile;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 100vw;
  height: 118px;
  border-bottom: 1px solid $accent-1;
  padding-bottom: 18px;

  @media (max-width: $med-break) {
    padding-top: 10px;
    margin-bottom: 0;
    border-bottom: none;
    border-top: 1px solid $accent-1;
    align-items: flex-end;
  }

  
  @media (max-width: 690px) {
    flex-direction: column-reverse;
    align-items: center;

    .Socials {
      display: none;
    }

    .Controls {
      margin-top: 10px;
      margin-left: 0;
    }

      .SpritePosition {
        margin-top: -60px
    // @media (max-width: 732px) {
    // }

    // @media (max-width: $xs-break) {
    //   margin-top: -60px
    // }
  }
  }

  @media (max-width: $xs-break) {
    // display: flex;
    // justify-content: center;
    height: 40.5vw;
    padding: 0;
    // align-items: flex-start;
  }

  button { 
    @include cta-1;
    margin-top: 8px;
    padding: 6px 10px;
    
    @media (max-width: $med-break) {
      font-size: 10px;
      padding: 4px 8px;
    }
  }

  a {
    &.active {
      button {
        color: $black;
        background: $accent-1;
      }
    }
  }

  nav, .Socials {
    margin-right: 15px;
  }

  nav {
    max-width: 45%;
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    justify-content: flex-end;
    @media (max-width: $xs-break) {
      padding: 0;
      padding-top: 6px;
      max-width: 100%;
    }
  }

  .linksAndNav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
  }

  .Socials {
    margin-top: 16px;
    @media (max-width: $xs-break) {
      display: None;
    }
  }
}


.Controls {
  z-index: 35;
  position: relative;
  left: 0;
  width: min-content;
  transition: top 400ms, left 400ms; 
  margin-left: 15px;
  
  @media (min-width: $med-break) {
    top: 10px;
    left: 20px;
  }
  
  @media (max-width: $med-break) {
    margin-bottom: -15px;
    bottom: 5px;
    left: 0;
  }

  h1 {
    position:absolute;
    top: 0;
    font-family: 'Yellowtail', cursive;
    color: $accent-1;
    margin: 0;

    @media (max-width: $med-break) {  
      font-size: 26px;
    }

    @media (max-width: $sm-break) {
      font-size: 26px;
    }
    @media (max-width: $xs-break) {
      font-size: 8vw;
    }
  }
 
  #keys {
    width: 350px;
    @media (max-width: $med-break) {
      width: 300px;
    }
    @media (max-width: $xs-break) {
      width: 90vw;
    }
  }

  #spacebar, #left, #right, #up, #down {
    position: absolute;
    background: none;
    border: none;
    height: 41%;
    bottom: 9px;
    border-radius: 8px;
    cursor: pointer;
  }

  #left, #right, #up, #down {
    width: 10.5%;
  }

  #down, #left, #spacebar, #right {
    @media (max-width: $sm-break) {
      bottom: 15px;
    } 
  } 

  #up, #down {
    left: 72%;
    // @media (max-width: $sm-break) {
    //   left: 317px;
    // } 
  }
  
  #spacebar {
    width: 54.5%;
    left: 5px;
  }

  #up {
    bottom: 53%;
    // @media (max-width: $sm-break) {
    //   bottom: 75px;
    // }
  }

  #left {
    left: 59%;
  }

  #right {
    left: 86%;
  }
}
