@import 'variables.scss';

.App {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  left: 0;

  @media (max-width: $med-break) {
    flex-direction: column-reverse;
  }

  main {
    position: relative;
    height: 100%;
    width: 100%;
    
    section {
      position: absolute;
      inset: 0;
      overflow: hidden;
      overflow-y: scroll;
      padding: 10px 250px;
      transition: padding 400ms;

      @media (max-width: $med-break) {
        padding: 10px 150px;
      }

      @media (max-width: $sm-break) {
        // padding: 10px 100px;
      }

      @media (max-width: $sm-break) {
        padding: 10px 20px;
      }
      
    }
  }
}

.toast {
  overflow: auto;
}

.in-progress {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $bright-accent;
  font-size: 20px;
  white-space: nowrap;
  span {
    white-space: nowrap;
    font-family: 'Gloria Hallelujah', cursive;
  }
}
