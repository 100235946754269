@import 'variables.scss';

body {
  height: 100%;
  background-image: $black-tile-2;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-family: 'Brexter';
  font-size: 20px;
  span {
    font-size: 30px;
    font-family: 'Yellowtail'
  }
  @media (max-width: $sm-break) {
    font-size: 17px;
    span {
      font-size: 26px;
    }
  }
}

.Card {
  h2 {
    border-bottom: 2px solid $accent-2;
  }

  h3 {
    font-family: 'Brexter';
    font-size: 18px;
  }

  background-image: $white-tile;
  padding: 10px 30px;
  color: $black;
  width: min-content;
  white-space: nowrap;
  border-radius: 5px;
}
