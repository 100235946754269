@import 'variables.scss';

.resume {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  bottom: 0;
  z-index: 0;
  padding-top: 130px;
  width: 35vw;
  transition: left 400ms, width 400ms, height 400ms;
  border-radius: 0;
  
  h2 {
    margin-top: -180px;
  }

  h3, h4 {
    margin: 0;
    font-style: italic;
  }

  div {
    margin: 6px 0 24px;
    white-space: wrap;
  }

  .resume-download {
    margin-left: -30px;
    display: flex;
    border: none;
    background: none;
    width: min-content;
    align-self: center;
    align-items: center;  
    opacity: 0.6;
    transition: opacity 400ms;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    > * {
      margin: 4px;
    }
  }
  
  @media (max-width: $med-break) {
    h3 {
      font-size: 16px;
    }

    h4 {
      font-size: 12px;
    }
  }
  
  @media (max-width: $sm-break) {
    top: 70px;
    width: calc(100vw - 60px);
  }

  &.open {
    left: 0px;
  }

  &.closed {
    left: -400vw;
  }
}

.techBadges {
  position: absolute;
  left: 43vw;
  margin-right: 40px;
  transition: bottom 400ms, top 400ms;
  text-align: right;

  img {
    margin: 2px 3px;
  }
  
  @media (max-width: $sm-break) {
    animation-name: move;
    -webkit-animation-name: move;
    animation-duration: 26s;
    -webkit-animation-duration: 26s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-direction: reverse;
    -webkit-animation-direction: reverse;
    
    &:hover {
      animation-play-state: paused;
      -webkit-animation-play-state: paused;
    }
  }
  @media (max-width: xs-break) {
    animation-duration: 15s;
    -webkit-animation-duration: 15s;
  }
  
  &.open {
    @media (min-width: $med-break) {
      bottom: 45px;
    }
    @media (max-width: $med-break) {
      bottom: 210px;
      // top: 60px;
      margin-right: 40px;
    }
    @media (max-width: $sm-break) {
      white-space: nowrap;
      width: 100vw;
      width: 100vw;
      left: 5px;
      top: 20px;
    }
  }
  
  &.closed {
    @media (min-width: $med-break) {
      bottom: -500px;
    }
    @media (max-width: $med-break) {
      top: -1000px;
    }
  }
}

@keyframes move {
  0% {
    margin-left: -780vw;
  }
  100% {
    margin-left: 110vw;
  }
}

@-webkit-keyframes move {
  0% {
    margin-left: -780vw;
  }
  100% {
    margin-left: 110vw;
  }
}
