@import "variables.scss";

.Characters {
   position: absolute;
   transition: top 400ms, bottom 400ms, opacity 200ms;
   z-index: 5;
   width: 400px;

   button {
      margin: 4px;
   }
}

.PlayerSettings-menu-closed {
   .Characters {
      right: 10px;
      opacity: 0;
   @media (min-width: $med-break) {
      top: -150px;
   }
   @media (max-width: $med-break) {
      bottom: -150px;
   }
   @media (max-width: 800px) {
      right: 0px;
      bottom: -100vh;
      width: 60vw;
   }
   }
}

.PlayerSettings-menu-open {
   .Characters {
      right: 10px;
      opacity: 1;
   @media (min-width: $med-break) {
      top: 140px;
   }
   @media (max-width: $med-break) {
      bottom: 150px;
   }
   @media (max-width: $xs-break) {
      bottom: 41vw;
      margin: 0;
      right: 0;
      width: 60vw;
   }
   }
}
