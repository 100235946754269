@import 'variables.scss';

.Contact {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  transition: top 200ms, padding 400ms !important;
  height: 100%;
  display: flex;
  align-items: center;
  
  &.closed {
    top: -100vh;
  }
  
  &.open {
    top: 0px;
  }
}

.Contact-form {
  box-sizing: border-box;
  border-radius: 10px;
  background-color: rgba(225, 221, 208, 0.1);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: padding 200ms;
  padding: 20px 120px;
  width: 100%;
  
  h2 {
    color: $accent-1
  }

  label {
    color: $white;
    text-transform: lowercase;
    margin-bottom: 5px;
    font-family: 'Brexter';
    span {
      color: $accent-2;
    }
  }

  textarea {
    margin-bottom: 10px;
    background-image: $white-tile;
    border-radius: 5px;
  }

  input {
    @include cta-1;
    align-self: flex-end;
    margin: 10px 0;
    padding: 4px 8px;
    font-size: 20px;
    margin-right: 2px;
    
    @media (max-width: $xs-break) {
      font-size: 14px;
      margin: 5px 0;
    }
  }

  @media (min-width: $lg-break) {
    padding: 160px 168px;
  }   
    
  @media (max-width: $med-break) {
    padding: 20px 70px;
  }

  @media (max-width: $sm-break) {
    padding: 20px 40px;
  }
    
  @media (max-width: $xs-break) {
    background-color: rgba(225, 221, 208, 0);
    left: 0;
    padding: 5vw 5vw;

    .Socials {
      align-self: center;
      margin-top: -43px;
      a {
        color: rgba(225, 221, 208, 0.2);
      }
      @media (max-width: $xs-break) {
        margin-top: -35px;
      }
    }
  }

  .Socials {
    align-self: center;
    margin-top: -43px;
    
    a {
      color: $black;
    }
    a:active {
      color: $accent-1;
    }
    a:hover {
      color: $accent-1;
    }
  }

  .sender { 
    display: flex;
    justify-content: space-between;
    width: 100%;
    div {
      width: 45%;
      display: flex;
      flex-direction: column;
    }
  }
    
  .message, .subject {
    width: 98.5%;
  }
}
