@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Unbounded:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');

$black: #1f1f1f;
$background-accent: #467f78;
$accent-1: #66d36e;
$dark-accent-1: #2b9833;
$accent-2: #F46036;
$bright-accent: #101e1c;
$dark-accent: #DB764B;
// $bright-accent: #eba73b;
// $dark-accent: #D75325;
$white: #E1DDD0;

$lg-break: 1500px;
$med-break: 1250px;
$sm-break: 875px;
$xs-break: 550px;
$black-tile: url('/assets/black-tile.png');
$black-tile-2: url('/assets/black-tile-2.png');
$white-tile: url('/assets/white-tile.png');

@font-face {
    font-family: Brexter;
    src: url("/assets/Brexter.otf") format("opentype");
}

@mixin cta-1 {
    color: $accent-1;
    background: none;
    border: solid $accent-1 1px;
    font-weight: bold;
    margin: 0 4px;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    transition: 
    color 400ms, 
    background 400ms, 
    border-color 400ms,
    font-size 400ms,
    padding 400ms;

    &:hover {
    background: $accent-1;
    color: $black;
    }
    &:active {
    background: $dark-accent-1;
    color: $black;
    border-color: $dark-accent-1;
    }
}
