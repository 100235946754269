@import 'variables.scss';

.Snarklet {

    .body {
        img {
            margin-left: -30px;
            margin-right: 10px;
        }
    }

    &.open {
        left: 0;
    }
    
    &.closed {
        left: -200vw
    }
}
