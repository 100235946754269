@import 'variables.scss';

.Characters {
  display: flex;
  justify-content: center;
  width: 500px;
  flex-wrap:wrap;
  margin: 0 auto;
  button {
    @include cta-1;
    height: 90px;
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    margin: 10px;
    border-width: 3px;
    background-color: $black;

    .SpritePosition {
      position: relative;
      margin-top: -20px;
      margin-bottom: 5px;
    }
  }

}
