@import 'variables.scss';

.Music {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px !important;

  @media (max-width: $med-break) {
    margin-top: 40px;
  }

  .band {
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: left 400ms, right 400ms, top 400ms;
    margin-bottom: 30px;

    .socials {
      z-index: 20;
      margin-left: 16px;
      a {
        color: $accent-1;
        margin: 0 5px;
      }
    }
    
    h2 {
      display: flex;
      align-items: center;
      color: $accent-1;
      white-space: nowrap;
      margin: 0 50px;
      span {
        margin: 0 8px;
      }

      @media (max-width: $xs-break) {
        margin: 0;
      }

    }

    img {
      width: 250px;
      border-radius: 50%;
  
      @media (max-width: $sm-break) {
        width: 28vw;
        height: 20vw;
        margin: 0;
      }
      @media (max-width: $xs-break) {
        width: 130px;
        height: 130px;
      }
    }

    .body {
      margin: 0;
      box-sizing: border-box;
      display: flex;
      color: $white;
      transition: margin 400ms;

      .description {
        margin: 4px 0px;

        a {
          color: $accent-2;
        }

        @media (max-width: $sm-break) {
          margin: 4px 20px;
        }

        @media (max-width: $xs-break) {
          font-size: 14px;
        }
      }
    } 
  }
}
